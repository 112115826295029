import React from 'react';
import { Row } from 'react-bootstrap';
import * as Styled from './blogCardStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const BlogCard = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  return (
    <Styled.Body>
      <Row className="g-0 justify-content-center">
        {sectionData?.listOfImagesWithPath &&
          sectionData?.listOfImagesWithPath?.map((blogCard, index) => {
            return (
              <React.Fragment key={index}>
                <Styled.Columns xl={4} className="px-0">
                  <Styled.BlogAnchor href={isExternalUrlHref(blogCard?.path)? blogCard?.path
                   :
                   addTrailingSlash( 
                    process.env.GATSBY_DOMAIN_URL + blogCard?.path
            )}
                  target = {
                    isExternalUrl(blogCard?.path)? '_blank' : '_self'
                  }
                  >
                    <Styled.ListingGridImage
                      backgroundImage={blogCard?.image?.file?.url}
                    />
                    <Styled.ListingGridDate>
                      {blogCard?.title}
                    </Styled.ListingGridDate>
                    <Styled.ListingGridDetails className="listing-grid-box-details">
                      {blogCard.richText &&
                        documentToReactComponents(
                          JSON.parse(blogCard.richText.raw),
                          optionsMainStyle
                        )}
                    </Styled.ListingGridDetails>
                  </Styled.BlogAnchor>
                </Styled.Columns>
              </React.Fragment>
            );
          })}
      </Row>
    </Styled.Body>
  );
};

export default BlogCard;
